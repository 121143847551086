import "./ProjectContainer.css"; // import CSS sheet to alow styling of the component
import { projectList } from "./ProjectList.js"; // This is where we store the location of the thumbnails, and all project information.
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
// this is a component that accesses our projectList object and prints it to screen.

function ProjectContainer(props) {
  const projects = projectList;

  return (
    <div>
      <Container id="projectContainer">
        <h1 id="projectHeader" className="container" style={{ width: `100vw` }}>
          Projects
        </h1>

        {projects.map((project, key) => (
          <div key={key}>
            <div id="projectBox" datakey={key}>
              <img
                id="project_img"
                className=""
                alt={project.photo_Alt}
                src={project.photo_Url}
              />
            </div>
            <div datakey={key} className="p-1" id="hover_text">
              <h4>{project.title}</h4>
              <p>{project.description}</p>
              {project.code_Url && (
                <a href={project.code_Url}>
                  <button className=" btn btn-primary m-1 ">Code</button>
                </a>
              )}
              {project.live_Url && (
                <a href={project.live_Url}>
                  <button className=" btn btn-primary m-1">Live</button>
                </a>
              )}
            </div>
          </div>
        ))}
      </Container>
    </div>
  );
}
export default ProjectContainer;
