import "App.css";
import Home from "components/Home/Home";
import Header from "components/Header/Header";
import {
  Routes,
  Route,
  Link,
  useRouteMatch,
  useParams,
  BrowserRouter,
} from "react-router-dom";
import Contact from "components/Contact/Contact";

//if we need to add more routes, we add them here and to the Header component if navigation is necessary directly.
function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route exact path={"/"} element={<Home />}></Route>
      </Routes>
    </div>
  );
}

export default App;
