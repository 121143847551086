import digitalAdvertThumb from "./images/digitalAdvertThumb.png";
import miniForumThumb from "./images/miniForumThumb.png";
import quoteFetcher from "./images/quote_fetcher.png";

export const projectList = [
  /*    {
      title: "mini Forum",
      description: "A forum that allows communities to connect.",
      photo_Url: miniForumThumb,
      photo_Alt: "A photo of my mini Forum application",
      live_Url: "https://heroku.com/",
      code_Url: "https://github.com/destinTech"
    },*/
  {
    title: "PetSitter",
    description:
      "A full stack MERN application created in a team setting with a gitflow workflow. 1 month agile sprint with code review. ",
    photo_Url: digitalAdvertThumb,
    photo_Alt: "A MERN stack application",
    live_Url: null,
    code_Url: "https://github.com/hatchways/team-scallops/tree/develop",
  },
  {
    title: "Random Quote Fetcher",
    description:
      "A project using React and bootstrap that displays quotes and allows them to be shared to twitter, available on npm.",
    photo_Url: quoteFetcher,
    photo_Alt: "A screenshot of the a random quote fetcher",
    live_Url: "https://codepen.io/destintech-the-reactor/pen/ExXormm",
    code_Url: "https://github.com/destintech/quote-o-matic",
  },
];
