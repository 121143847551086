import "./Contact.css";
import ReCAPTCHA from "react-google-recaptcha";

import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";

export const Contact = () => {
  const [respColor, setRespColor] = useState();
  const [status, setStatus] = useState();
  const SUCCESS = ">>> Message sent! I'll get back to you soon!";
  const FAILURE =
    ">>> There was a problem sending the message... Please send me a message on Linkedin. Thank you.";
  const SENDING = "> Sending...";

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setRespColor("#81E979");
    setStatus(">> Sending...");

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          setRespColor("#81E979");
          setStatus(SUCCESS);
        },
        (error) => {
          setRespColor("red");
          setStatus(FAILURE);
        }
      );
  };
  return (
    <div id="contact" className="container">
      <script
        src="https://www.google.com/recaptcha/api.js"
        async
        defer
      ></script>

      <h1>Looking to work together?</h1>
      {status && <h4 style={{ color: respColor }}> {status}</h4>}
      <div id="contact_form" className="container p-2">
        <p> </p>
        <form ref={form} onSubmit={sendEmail}>
          <div className="row">
            <div className="col">
              <label htmlFor="name">Name:</label>

              <input
                name="name"
                id="name"
                className="form-control"
                type="text"
                placeholder="Name..."
              ></input>
            </div>
            <div className="col">
              <label htmlFor="email">Your email:</label>
              <input
                name="email"
                id="email"
                className="form-control"
                type="email"
                placeholder="Email..."
              ></input>
            </div>
            <label className="mt-3">
              Message:
              <textarea
                name="message"
                placeholder="Message..."
                id="message"
                className="form-control "
                type="text"
              ></textarea>
            </label>

            <ReCAPTCHA
              className=" mt-3"
              id="recaptcha"
              sitekey={process.env.REACT_APP_SITE_KEY}
            />
            <br />
          </div>
          <button
            id="success"
            type="submit"
            value="send"
            onClick={() => {
              setRespColor("#81E979");
              setStatus(SENDING);
            }}
            className="btn mt-3 btn-success"
          >
            {" "}
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
