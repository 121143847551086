import "./Header.css"; // import CSS sheet to alow styling of the component
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.css";
import { Nav, Navbar, Image } from "react-bootstrap";
import favicon from "../../images/logo192.png";

function Header(props) {
  return (
    <div id="header">
      <Navbar
        id="navbar"
        style={{
          backgroundColor: "#363635",
        }}
        variant="dark"
        fixed="top"
      >
        <Navbar.Brand href="/#">
          <Image id="logo" height="35em" src={favicon}></Image>
        </Navbar.Brand>
        <Nav className="topnav-left"></Nav>
        <Nav className="topnav-right">
          <Nav.Link href="#intro_page">About</Nav.Link>
          <Nav.Link href="#projectContainer">Projects</Nav.Link>
          <Nav.Link href="#contact">Contact</Nav.Link>
          <Nav.Link
            target="_blank"
            href="https://www.linkedin.com/in/destintech/"
          >
            <nobr>
              <span className="bi bi-linkedin"> LinkedIn</span>
            </nobr>
          </Nav.Link>
        </Nav>
      </Navbar>
    </div>
  );
}
export default Header;

//Old implemnetation to loop through variables for the nav bar, don't use unless there's a decent reason as it decreased readability.
// <nav id="navbar" class=" navbar-nav">
//<ul class="navbar-nav">
//{navLinks.map((navLink) => (
//  <Link class="nav-item" to={navLink.url}> {navLink.title}</Link>
//))}
//</ul>
//</nav>
//https://coolors.co/363635-595a4a-b0fe76-81e979-8fbb99
