import React, { Component } from "react";

import { Nav, Navbar, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import ProjectContainer from "../ProjectContainer/ProjectContainer";
import Contact from "../Contact/Contact";

import undraw_space from "../../images/undraw_Space.gray.svg";
import "./Home.css"; // import CSS sheet to alow styling of the component

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.title = " destin.dev";
  }

  render() {
    return (
      <div id="home">
        <div id="landing_page">
          <div id="hero_area">
            <div id="hero_box" className="text-center">
              <h1 id="hero_text">Destin Developments</h1>
              <h3 id="hero_description">
                Performant web applications using the latest tech.
              </h3>

              <Nav.Link
                href="/#contact"
                id="button_cta"
                className=" btn btn-success "
                onClick={() => {
                  alert(
                    "Send me an email, I'll get back to you quickly. Thanks!"
                  );
                }}
              >
                Contact Me
              </Nav.Link>
            </div>

            <img
              className="img-fluid"
              id="hero_image"
              alt="An illustration of a man holding a smart phone "
              src={undraw_space}
            ></img>
          </div>
          {/* <div
            id="down_arrow"
            className="bi bi-arrow-bar-down animate__animated animate__bounce animate__delay-5s  animate__repeat-3 "
          ></div> */}
        </div>
        <div id="project_page">
          <ProjectContainer />
        </div>
        <div className="container" id="intro_page">
          <div className=" container text-left " id="intro">
            <h1>A little about me..</h1>
            <h3>
              Web development is a passion of mine that I've been polishing for
              many years since I first touched HTML, CSS, and Ubuntu Linux!
              <br />
              <br />
              Learning javascript was the next step in my career. It wasn't
              easy, but the skills allowed me to progress beyond static pages
              and make interactive components for websites.
              <br />
              Soon after, I attended a course in Java development to hone my
              back-end skills and knowledge of computer science.
              <br />
              <br />
              I took to the books, learning everything I could. React.js,
              node.js, and express. MongoDB, SPA's, and REST API... You name it.
              <br />
              <br />
              After touching up my skills, I applied for the Hatchways
              accelerator program and was accepted. I developed PetSitter there
              with my team in four 1-week sprints, where I learned the value of
              teamwork in software development.
              <br />
            </h3>
          </div>
        </div>

        <Contact />
      </div>
    );
  }
}
export default Home;
